import * as React from "react"
import PropTypes from "prop-types"
import {css, Global} from "@emotion/react"
import {f} from "~/emotionStyles/function"

import Header from "./header"
import "~/assets/css/reset.css"
import "~/assets/css/swiper.css"


const Layout = ({ children }) => {
  return (
    <>
      <Global
        styles={[global]}
      />
      <Header />
      <main css={main}>{children}</main>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

const global = css({
  "body":{
    fontFamily:"Helvetica Neue, Helvetica, Hiragino Sans, Hiragino Kaku Gothic ProN, Arial, Yu Gothic, Meiryo, sans-serif"
  },
  ".display-pc": {
    [f.pc()]: {
      display: "block"
    },
    [f.sp()]: {
      display: "none!important"
    }
  },
  ".display-sp": {
    [f.pc()]: {
      display: "none!important"
    },
    [f.sp()]: {
      display: "block"
    }
  },
  "img": {
    width: "100%",
    display: "block",
    lineHeight: 1
  }
})

const main = css({
  [f.pc()]: {
    paddingLeft:'150px',
    width:'100%',
    backgroundColor:'#FAFAFA'
  },
  [f.sp()]: {
    paddingTop:'70px',
    width:'100%',
    backgroundColor:'#FAFAFA'
  },
})

export default Layout
