import * as React from "react"
import {css} from "@emotion/react"
import {f} from "~/emotionStyles/function"
import { Link } from "gatsby"
class Header extends React.Component{
  toggleMenu(){
    const button = document.querySelector('[data-button]')
    const target = document.querySelector('[data-target]')
    const targetHeight = document.querySelector('[data-height]')
    button.addEventListener('click',() =>{
      const height = targetHeight.clientHeight
      if(target.classList.contains('is-open')){
        target.classList.remove('is-open')
        target.style.height = `${0}px`
        button.classList.remove('is-active')
      }else{
        target.classList.add('is-open')
        target.style.height = `${height}px`
        button.classList.add('is-active')
      }
    })
    window.addEventListener('resize',()=>{
      const height = targetHeight.clientHeight
      if(target.classList.contains('is-open')){
        target.style.height = `${height}px`
      }
    })
  }
  componentDidMount(){
    this.toggleMenu()
  }
  render(){
    return(
      <header css={wrap}>
        <div css={inner}>
          <div css={logo}>
            <Link to ="/">
            <img src="/images/logo.png" alt="劇団おおたかの風" />
            </Link>
          </div>
          <button css={button} data-button>
            <span></span>
            <span></span>
            <span></span>
          </button>
          <ul css={list} className="display-pc">
            <li><Link to="/#about">劇団について</Link></li>
            <li><Link to="/#next">次回公演</Link></li>
            <li><Link to="/#archive">過去公演</Link></li>
            <li><Link to="/#contact">お問い合わせ</Link></li>
          </ul>
        </div>
        <div data-target css={spWrap}>
          <ul css={list} className="display-sp" data-height>
            <li><Link to="/#about">劇団について</Link></li>
            <li><Link to="/#next">次回公演</Link></li>
            <li><Link to="/#archive">過去公演</Link></li>
            <li><Link to="/#contact">お問い合わせ</Link></li>
          </ul>
        </div>
    
      </header>
    )
  }
}

const wrap = css ({
  [f.pc()] :{
    position:'fixed',
    width :'150px',
    height: '100vh',
    backgroundColor:'#fff',
    left:'0',
    top:'0',
  },
  [f.sp()] :{
    position:'fixed',
    width :'100%',
    height: 'auto',
    backgroundColor:'#fff',
    left:'0',
    top:'0',
    zIndex:"100"
  }
});

const inner = css({
  [f.pc()] :{
    padding: '24px 0px 0px 14px'
  },
  [f.sp()] :{
    padding: '12px 16px',
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center'
  }
});

const logo = css({
  [f.pc()] :{
    width :'115px',
    marginBottom:'50px',
  },
  [f.sp()] :{
    width :'115px',
  }
});


const button = css({
  [f.sp()] :{
    width :'20px',
    height:'15px',
    position:'relative',
    "span":{
      width:'100%',
      height:'2px',
      backgroundColor:'#333',
      "&:nth-of-type(1)":{
        position:'absolute',
        top:'0',
        left:'0',
        transition:'transform .3s ease-out'
      },
      "&:nth-of-type(2)":{
        position:'absolute',
        top:'50%',
        left:'0',
        opacity:'1',
        transition:'opacity.3s ease-out'
      },
      "&:nth-of-type(3)":{
        position:'absolute',
        top:'100%',
        left:'0',
        transition:'transform .3s ease-out'
      },
    },
    "&.is-active":{
      "span":{
        "&:nth-of-type(1)":{
          transform:'rotate(45deg)',
          top:'50%',
        },
        "&:nth-of-type(2)":{
          opacity:'0'
        },
        "&:nth-of-type(3)":{
          transform:'rotate(-45deg)',
          top:'50%',
        },
      }
    }
  }
});

const list = css({
  "li":{
    color:'#333',
    fontWeight:'bold',
    [f.pc()] :{
      color:'#333',
      paddingBottom:'30px',
    },
    [f.sp()] :{
      fontSize:'16px',
      paddingBottom:'20px',
      textAlign:'center'
    }
  }
})

const spWrap = css({
  height:'0',
  overflow:'hidden',
  transition:'height .3s ease-out',
  "&.is-open":{
    display:'block'
  }
})

export default Header
